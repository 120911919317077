import axios from "axios"

const baseUrl = process.env.REACT_APP_url
const apiKey = process.env.REACT_APP_api
const angelAPI = '0TrJfaqR'
const secretKey = '594b497e-7bd3-4570-9458-0593356c1a93'

const axiosConfig = {
    headers: {
        'Content-Type': 'application/json charset=UTF-8',
    }
}

export const getMarketIndices = async (view, setView) => {
    let response = await axios.get(`https://api.stockmarketapi.in/api/v1/indexprice?token=${apiKey}&indexcode=NIFTY,BANKNIFTY`).then(response => response.data).then(response => response.data)
    await setView(response)
}

let count = 0;
export const getChartData = async (marketName, view, setView) => {
    let response = await axios.get(`https://api.stockmarketapi.in/api/v1/indexprice?token=075a44ae8af9e9194075d0a2071007403988c7f88ebcb58c6ce932b957b6f332&indexcode=${marketName}`).then(response => response.data).then(response => response.data)
    var z = 5; //minutes interval
    var x = []; // time array
    var tt = 540; // start time
    var ap = ['AM', 'PM']; // AM-PM

    for (var i = 0; tt < 15.6 * 60; i++) {
        var hh = Math.floor(tt / 60)
        var mm = (tt % 60)
        x[i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh / 12)]
        tt = tt + z
    }
    await setView(prev => [...prev, {
        x: new Date(x[count]),
        y: Number(response[0].ltp + Math.random())
    }])
    count++
    console.log(x)
}

export const getTopGainers = async (view, setView) => {
    let response = await axios.get(`https://api.stockmarketapi.in/api/v1/topgainers?token=${apiKey}`).then(response => response.data).then(response => response.data)
    await setView(response)
}

export const getTopLosers = async (view, setView) => {
    let response = await axios.get(`https://api.stockmarketapi.in/api/v1/toplosers?token=${apiKey}`).then(response => response.data).then(response => response.data)
    await setView(response)
}

export const fetchOrders = async (id, orders, setOrders) => {
    const body = {
        id: id
    }
    let response = await axios.post(`${baseUrl}/userorder`, JSON.stringify(body), axiosConfig).then(response => response.data).catch(err => console.error(err))

    await setOrders(response)
}

export const fetchWatchList = async (list, setList) => {
    let response = await axios.get(`https://api.stockmarketapi.in/api/v1/allstocks?token=${apiKey}`).then(response => response.data).then(response => response.data).catch(err => console.error(err))

    await setList(response.slice(0, 50))
}

export const fetchAccount = async (account, setAccount, id) => {
    const body = {
        id: id
    }
    let response = await axios.post(`${baseUrl}/useraccount`, JSON.stringify(body), axiosConfig).then(response => response.data).catch(err => console.error(err))
    await setAccount({ usedMargin: response[0].used_margin, balance: response[0].amount, originalBalance: response[0].orginal_amount })
}

export const requestAddMoney = async (data, setData) => {
    let response = await axios.get(`${baseUrl}/request`, axiosConfig).then(response => response.data).catch(err => console.error(err))

    const barcode = await axios.get(`${process.env.REACT_APP_barcode}/${response.barcode}`, { responseType: 'blob' }).then(response => response.data)

    await setData({
        id: response.id,
        account: response.account,
        barCode: URL.createObjectURL(barcode),
        ifsc: response.ifsc,
        upi: response.upi_id,
        contactNo: response.contact
    })
}

export const fetchAddTransactions = async (addMoney, setAddMoney, id) => {
    const body = {
        id: id
    }
    let addMoneyResponse = await axios.post(`${baseUrl}/useraddmoney`, JSON.stringify(body), axiosConfig).then(response => response.data).catch(err => console.error(err))
    await setAddMoney(addMoneyResponse)
}

export const fetchWithdrawalTransactions = async (withdrawMoney, setWithdrawMoney, id) => {
    const body = {
        id: id
    }

    let withdrawMoneyResponse = await axios.post(`${baseUrl}/userwithdrawal`, JSON.stringify(body), axiosConfig).then(response => response.data).catch(err => console.error(err))
    await setWithdrawMoney(withdrawMoneyResponse)
}

export const requestWithdrawal = async (request, setRequest) => {
    const body = {
        id: request.id,
        amount: request.requestAmount
    }

    let withdrawMoneyResponse = await axios.post(`${baseUrl}/userwithdrawaladd`, JSON.stringify(body), axiosConfig).then(response => response.data).catch(err => console.error(err))
    return withdrawMoneyResponse
}

export const fetchBalance = async (balance, setBalance, id) => {
    const body = {
        id: id
    }

    let response = await axios.post(`${baseUrl}/useraccount`, JSON.stringify(body), axiosConfig).then(response => response.data).catch(err => console.error(err))
    await setBalance(parseInt(response[0].orginal_amount))
}

export const searchQuery = async (query, setSearchResults) => {
    const body = {
        search: query,
    }

    let response = await axios.post(`${baseUrl}/search`, JSON.stringify(body), axiosConfig).then(response => response.data).catch(err => console.error(err))
    setSearchResults(response)
}

export const getTradeDetails = async (tradeData, id, setTradeData) => {
    const body = {
        id: id
    }

    let response = await axios.post(`${baseUrl}/trade`, JSON.stringify(body), axiosConfig).then(response => response.data).catch(err => console.error(err))
    await setTradeData(response)
}

export const bookTrade = async (response, setResponse, premium, data, id, total, account) => {
    const body = {
        order_id: data.id,
        user_id: id,
        buy_type: data.market,
        cepe: data.cepe,
        order: data.name,
        buying_price: data.price,
        lot: premium.shares,
        stop_loss: premium.stopLoss,
        target: premium.target === 0 ? 100000000 : premium.target,
        buying_total: total,
        expiry: data.expiry,
        original_balance: account.originalBalance
    }

    let res = await axios.post(`${baseUrl}/booktrade`, JSON.stringify(body), axiosConfig).then(response => response.data).catch(err => console.error(err))
    await setResponse(res)
}

export const fetchTradeOrders = async (id, tradeOrders, setTradeOrders) => {
    const body = { id: id }

    let response = await axios.post(`${baseUrl}/tradeorder`, JSON.stringify(body), axiosConfig).then(response => response.data).catch(err => console.error(err))
    await setTradeOrders(response)
}

export const sellOrder = async (id, selling_price, order_id, user_id) => {
    const body = {
        id: id,
        selling_price: selling_price,
        order_id: order_id,
        user_id: user_id,
    }

    let response = await axios.post(`${baseUrl}/tradesell`, JSON.stringify(body), axiosConfig).then(response => response.data).catch(err => console.error(err))
    return response
}

export const updateUserProfile = async (id, updateUser, response, setResponse) => {
    const body = {
        id: id,
        name: updateUser.name,
        contact: updateUser.contactNo,
        email: updateUser.email,
        password: updateUser.password,
        username: updateUser.username
    }

    let res = await axios.post(`${baseUrl}/updateuser`, JSON.stringify(body), axiosConfig).then(response => response.data).catch(err => console.error(err))

    return res
}